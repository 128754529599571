<template>
  <div>
    <center>
      <img src="../assets/penfold_icon.png" class="icon" alt="Penfold" />
      <br />
      <img src="../assets/penfold_text.png" class="text" alt="Penfold" />
    </center>
  </div>
</template>

<script>
export default {
  name: "Head"
};
</script>

<style scoped>
@media screen and (max-width: 425px) {
  .icon {
    max-width: 200px;
  }
  .text {
    max-width: 200px;
  }
}
.icon {
  max-height: 350px;
}
.text {
  max-height: 120px;
}
</style>