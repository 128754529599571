<template>
  <div>
    <a :href="link" target="__blank">
      <button class="btn">
        <font-awesome-icon :icon="fontAwesome" class="icon" />
        <span class="text">{{text}}</span>
      </button>
    </a>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["fontAwesome", "text", "link"]
};
</script>

<style scoped>
.btn {
  margin-top: 10px;
  margin-left: 20px;
  float: left;
  background: #2e7da4;
  border: solid 3px #0f2732;
  font-family: "Roboto", sans-serif;
  color: white;
  text-align: center;
  width: 170px;
  height: 45px;
  border-radius: 15px;
}

.btn:hover {
  cursor: pointer;
}

.icon {
  font-size: 1.4em;
}

.text {
  margin-left: 10px;
  font-size: 1.2em;
}

@media screen and (max-width: 425px) {
  .btn {
    float: initial;
    margin-left: 0;
    width: 200px;
    height: 45px;
  }
}
</style>