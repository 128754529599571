<template>
  <div>
    <Button
      :fontAwesome="faPlus"
      text="Invite Bot"
      link="https://discordapp.com/api/oauth2/authorize?client_id=544624869757681675&permissions=8&scope=bot"
    />
    <Button
      :fontAwesome="faGithub"
      text="Source Code"
      link="https://github.com/penfoldium/penfoldbot/"
    />
    <Button :fontAwesome="faDiscord" text="Support Server" link="https://discord.gg/uaRkbEH" />
  </div>
</template>

<script>
import Button from "./button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faDiscord } from "@fortawesome/free-brands-svg-icons";

export default {
  name: "Buttons",
  components: {
    Button
  },
  data() {
    return {
      faPlus,
      faGithub,
      faDiscord
    };
  }
};
</script>