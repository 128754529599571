<template>
  <div id="dbl">
    <a href="https://top.gg/bot/544624869757681675">
      <img class="image" src="https://top.gg/api/widget/544624869757681675.svg" alt="Penfold" />
    </a>
  </div>
</template>

<script>
export default {
  name: "DBL"
};
</script>

<style scoped>
    #dbl {
        margin: 70px;
    }
    .image {
        filter: drop-shadow(0px 0px 5px black);
    }

    @media screen and (max-width: 425px) {
      #dbl {
        margin: 0;
        margin-top: 30px;
      }
    }
</style>