<template>
  <div id="app">
    <center>
      <Head />
      <Buttons />
      <DBL />
    </center>
  </div>
</template>

<script>
import Head from "./components/head";
import Buttons from "./components/buttons";
import DBL from "./components/dbl";

export default {
  name: "app",
  components: {
    Head,
    Buttons,
    DBL
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:500&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
  color: white;
  background: #2c2f33;
}

#app {
  width: 590px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 425px) {
  #app {
    width: 320px;
  }
}
</style>
